import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/views/Index.vue';
const MainPage = () => import('../views/Home/MainPage.vue');
const NewMainPage = () => import('../views/Home/NewMainPage.vue');
const ReportPage = () => import('../views/Home/ReportPage.vue');
const NewReportPage = () => import('../views/Home/NewReportPage.vue');

// 解除重複路由問題
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  // 新 UI 第一頁
  {
    path: '/NewMainPage',
    name: 'NewMainPage',
    component: NewMainPage
  },
  // 新 UI 第二頁
  {
    path: '/NewReportPage',
    name: 'NewReportPage',
    component: NewReportPage
  }
  // 困境頁面-三根香氛
  // {
  //   path: '/MainPage',
  //   name: 'MainPage',
  //   component: MainPage
  // },
  // {
  //   path: '/ReportPage',
  //   name: 'ReportPage',
  //   component: ReportPage
  // }
  // 錯誤頁面
  // {
  //   path: '*',
  //   redirect: ''
  // }
];
const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
