import Axios from 'axios';

const axios = Axios.create({
  baseURL: `${process.env.VUE_APP_VOISS_API_ENDPOINT}/s-analyze`,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_ANALYZE_API_KEY}`
  }
});

// 定義不同類型的 report 要存到的對應的位置
const reportTypeMap = {
  introduction: 'introduction',
  personal_data: 'personalData',
  personal_compare: 'personalCompare',
  personal_sentences: 'personalSentences'
};

export default {
  namespaced: true,
  state: {
    reportId: '',
    // 分析的第一頁
    introduction: null,
    // 個人成長軌跡
    personalData: null,
    // 個人成長軌跡 - 跟大家的比較 (那個圖表)
    personalCompare: null,
    // 個人成長軌跡 - 最底下的句子
    personalSentences: null
  },
  getters: {},
  mutations: {
    SET_REPORT_ID(state, id) {
      state.reportId = id;
    },
    // 保存個人成長軌跡
    SET_REPORT_DATA(state, report) {
      if (report && report.type) {
        let saveKey = reportTypeMap[report.type];
        state[saveKey] = report;
      } else {
        console.warn(`SET_REPORT_DATA(state, report), report 類型錯誤`);
      }
    }
  },
  actions: {
    // 拿分析資料
    updateReportData(context, data) {
      let { id, reports } = data;
      // console.log('VUE_APP_VOISS_API_ENDPOINT', process.env.VUE_APP_VOISS_API_ENDPOINT)
      return axios.post('/report', { s: id, reports }).then(async res => {
        context.commit('SET_REPORT_ID', id);

        if (process.env.NODE_ENV === 'development') console.log(res);
        let reports = res.data;
        // 預期獲得的應該是一個陣列
        if (Array.isArray(reports)) {
          for (let report of reports) {
            context.commit('SET_REPORT_DATA', report);
          }
        } else {
          console.warn(`updateReportData response format error`);
        }
      });
    }
  }
};
