<template>
  <div id="app">
    <router-view />
    <!-- 彈跳視窗 -->
    <q-dialog v-model="myAlert">
      <q-card>
        <!-- 提示字 -->
        <q-card-section>
          <div class="text-h6">{{ alertWord }}</div>
        </q-card-section>
        <!-- 確定按紐 -->
        <q-card-actions v-if="alertCheck" align="right">
          <q-btn flat label="確定" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: 'App',
  data() {
    return {
      myAlert: false,
      // 提示框"確定"的按鈕
      alertCheck: false
    };
  },
  computed: {
    ...mapState(['alert', 'alertWord'])
  },
  watch: {
    alert: function() {
      // 若有需要"確定"的按紐，彈跳框則不一秒後自動消失
      if (this.alert === 'showAlertCheck') {
        this.myAlert = true;
        this.alertCheck = true;
        return;
      }
      // 若沒有確定按紐，一秒後會自己消失
      if (this.alert) {
        this.myAlert = true;
        setTimeout(() => {
          this.myAlert = false;
          this.CHANGE_ALERT_STATUS(false);
          this.CHANGE_ALERT_WORD('');
        }, 1000);
      }
    },
    myAlert: function() {
      console.log(this.myAlert);
      // 關閉提示框
      if (!this.myAlert) {
        this.alertCheck = false;
        this.CHANGE_ALERT_STATUS(false);
        this.CHANGE_ALERT_WORD('');
      }
    }
  },
  created() {
    if (this.alert) {
      this.CHANGE_ALERT_STATUS(false);
    }
    // 偵測是否是用 chrome 開啟，如果不是，跳出彈跳窗提醒 TODO: 會自己關掉!!!!!!!!
    const isChrome = navigator.userAgent.match('Safari');
    // console.log(isChrome);
    if (!isChrome) {
      const self = this;
      setTimeout(() => {
        self.CHANGE_ALERT_STATUS('showAlertCheck');
        self.CHANGE_ALERT_WORD('建議使用 Chrome 開啟網頁，來經歷更好的使用體驗喔!');
      }, 1000);
    }
  },
  methods: {
    ...mapMutations(['CHANGE_ALERT_STATUS', 'CHANGE_ALERT_WORD']),
    ...mapActions('ReportPageModule', ['updateReportData'])
  }
};
</script>
<style lang="scss">
@font-face {
  font-family: GenJyuuGothic;
  src: url('./assets/fonts/GenJyuuGothic-Medium.ttf');
}
body {
  // background: linear-gradient(180deg, #3c2657 0%, #30234e 33.85%, #202042 69.27%, #131d39 100%);
  background: linear-gradient(180deg, #fcedeb 0%, #e8f7fd 33.85%, #fed6bf 69.27%, #e8f7fd 100%);
  overflow-x: hidden;
  font-family: 'GenJyuuGothic', 'Roboto', 'PingFang TC' !important;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'GenJyuuGothic', 'Roboto', 'PingFang TC' !important;
  // background: linear-gradient(180deg, #3c2657 0%, #30234e 33.85%, #202042 69.27%, #131d39 100%);
  height: 100vh;
  max-width: 414px;
  margin: 0 auto;
  position: relative;
}
.q-dialog .q-card {
  border-radius: 12px;
  .q-card__section.q-card__section--vert {
    text-align: center;
    min-width: 129px;
  }
}
</style>
